.item {
  padding: 5px;
  background-color: rgb(241 241 241);
  border-radius: 5px;
  margin-bottom: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .discountPrice {
    text-decoration: line-through;
    color: #7a7a7a;
  }

  &:hover {
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
  }

  .wrapper {
    display: flex;
    align-items: center;

    .logo {
      border-radius: 5px;
    }

    .originPrice {
      letter-spacing: 1px;
      margin-left: 4px;
    }

    .name {
      letter-spacing: 1px;
      margin-left: 4px;
    }
  }


  .price {
    margin-left: 5px;
  }

}