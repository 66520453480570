@import '../../../styles/colors.module';

.currencyModal {
  width: 100%;
  height: 100%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;

  .modalContent {
    padding: 15px;
    border-radius: 10px;
    background-color: #eeeeee;

    border: 1px solid white;
    color: #484747;

    .close {
      display: flex;
      justify-content: flex-end;

      .btn {
        cursor: pointer;
        transition: 0.5s;transition: 0.5s;
        .svg {
          width: 20px;
        }

        &:hover {
          transition: 0.5s;
          color: #939393;
        }
      }
    }

    .item {
      display: flex;
      flex-direction: column;
      margin-bottom: 10px;

      .itemName {
        color: #4a4a4a;
        font-weight: 300;
        margin-bottom: 5px;
      }

      .loadingLoaderWrap {
        display: flex;
        justify-content: center;
      }
    }

    h4 {
      margin: 0px 0px 15px 0px;
      //color: #6a6a6a;
    }
  }
}

.header {
  position: fixed;
  width: 100%;
  z-index: 99;

  .container {
    width: 70%;
    margin: auto;
    padding: 5px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;

    .leftContainer {
      display: flex;
      align-items: center;

      .currencyInfo {
        background-color: rgb(215 176 61);
        border-radius: 5px;
        padding: 4px 10px;
        display: flex;
        align-items: center;
        color: white;
        box-shadow: 0 14px 28px rgb(243 243 243 / 25%), 0 10px 10px #8f8f8f;
        margin-left: 10px;
        cursor: pointer;
        font-size: 14px;

        &:hover {
          transition: 0.5s;
          box-shadow: 0 14px 28px rgb(243 243 243 / 25%), 0 10px 10px rgb(39 39 39);
        }

        span {
          padding: 0px 5px;
          color: #565656;
        }
      }

      .lang {
        display: flex;
        padding: 0 10px;
        color: #6e6e6e;

        .flag {
          padding: 0 5px;
          cursor: pointer;
        }

        .active {
          color: green;
          //text-decoration: underline;
          box-shadow: 0px 10px 5px -8px #007600;
        }
      }
    }

    .burgerMenu {
      margin-left: 25px;
      display: none;

      .burgerSvg {
        width: 22px;
      }
    }

    .rightContainer {
      display: flex;
      color: $gray-400;
      align-items: center;
      justify-content: flex-end;

      .category {
        cursor: pointer;
        margin-right: 10px;
        transition: 0.5s;
        position: relative;

        &:hover {
          transition: 0.5s;
          color: #f3f3f3;

          .categoryWrap {
            display: block;
          }
        }
        .categoryWrap {
          display: none;
          position: absolute;
          color: black;
          //margin: 0px;
          //
          //.link {
          //  color: $gray-400;
          //  cursor: pointer;
          //  list-style-type: none;
          //  margin-left: 25px;
          //  transition: 0.5s;
          //
          //  &:hover {
          //    transition: 0.5s;
          //    color: #ebeaea;
          //  }
          //}
        }
      }

      .user {
        cursor: pointer;
        margin-right: 10px;
        transition: 0.5s;
        position: relative;

        .userSvg {
          width: 22px;
        }
        .modal {
          position: absolute;
          display: none;
        }

        &:hover {
          transition: 0.5s;
          color: #f3f3f3;

          .modal {
            display: block;
          }
        }

        .login {
          margin-left: 10px;
        }
      }

      .cart {
        position: relative;
        cursor: pointer;
        transition: 0.5s;
        .cartSvg {
          width: 22px;
        }

        &:hover {
          transition: 0.5s;
          color: #ebeaea;
        }

        .cartAmount {
          position: absolute;
          left: 12px;
          bottom: -6px;
          border-radius: 0.8rem;
          background: rgb(255, 194, 15);
          color: rgb(39, 39, 39);
          font-size: 14px;
          font-weight: 500;
          width: 19px;
          height: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .cartTotal {
          margin-left: 10px;
          color: black;
        }
      }

    }

    .filler {
      background-color: rgb(39 39 39);
      border-radius: 50%;
      width: 50px;
      height: 50px;

      .logo {
        width: 50px;
        height: 50px;
        cursor: pointer;
      }
    }
  }

}

.headerNoColor {
  transition: 0.5s;
}

.headerColor {
  transition: 0.5s;
  //background-color: rgb(235 235 235 / 91%);
  background: linear-gradient(95.83deg, #b4d4ff 4.09%, #e3e3e3 64.5%), #fff0
}

.select {
  width: 100%;
  min-width: 15ch;
  max-width: 30ch;
  border: 1px solid var(--select-border);
  border-radius: 0.25em;
  padding: 0.25em 0.5em;
  cursor: pointer;
  line-height: 1.1;
  background-color: #fff;
  background-image: linear-gradient(to top, #f9f9f9, #fff 33%);
}


@media only screen and (max-width: 980px) {
  .header {
    .container {
      width: auto;
      position: relative;
      margin: 0px 12px;

      .leftContainer {
        .currencyInfo {
          font-size: 12px;
        }
      }

      .rightContainer {
        width: 100%;

        .category {
          display: none;
        }

        .user {
          display: none;
        }
      }

      .burgerMenu {
        display: block;
        color: $gray-400;
        cursor: pointer;

        &:hover {
          transition: 0.5s;
          color: #ebeaea;
        }
      }
    }
  }
}

@media only screen and (min-width: 1700px) {
  .header {
    .container {
      width: 1100px;
    }
  }
}