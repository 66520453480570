.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
  background-color: #4e4e4e26;
  border-radius: 20px;
  text-align: center;

  .wrap {
    display: flex;
    align-items: center;
    color: #565656;

    .emptyTitle {
      margin: 0px 10px;
      letter-spacing: 3px;
      text-align: center;
      color: #565656;
      font-weight: 500;
      font-size: 25px;
    }
  }
}

@media only screen and (max-width: 980px) {
  .container {
    .wrap {
      .emptyTitle {
        font-size: 18px;
      }
    }
  }
}
