.wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  height: 100%;

  .center {
    width: 300px;
    margin: auto;
    padding: 20px;
    border-radius: 30px;
    background: linear-gradient(95.83deg, #B4D4FF 4.09%, rgb(187 182 182) 64.5%),#fff;

    p {
      text-align: center;
    }
  }

  .submitButton {
    width: 100%;
  }
}

.link {
  text-decoration: underline;
  &:hover {
    color: #3fadff;
  }
}
