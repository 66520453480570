.card {
  position: relative;
  width: fit-content;
  border-radius: 10px;
  margin: 30px;
  background-color: #eee;
  box-shadow: 0 14px 28px rgb(243 243 243 / 25%), 0 10px 10px #8f8f8f;
  height: fit-content;
  transition: 0.5s;
  min-width: 240px;
  max-width: 240px;

  &:hover {
    position: relative;
    $border: 1px;
    transition: 0.5s;
    box-shadow: 0 10px 20px rgb(0 0 0 / 75%), 0 6px 6px rgb(0 0 0 / 78%);

    &:before {
      content: '';
      position: absolute;
      top: 0; right: 0; bottom: 0; left: 0;
      z-index: -1;
      margin: -$border; /* !importanté */
      border-radius: inherit; /* !importanté */
      background: linear-gradient(90deg, rgba(177,177,177,1) 0%, rgba(148,148,148,0) 26%, rgba(156,156,156,0) 75%, rgba(187,182,182,1) 100%);
    }
  }

  .flag {
    z-index: 1;
    position: absolute;
    margin-top: 51px;
    margin-left: -7px;
  }

  .price {
    z-index: 1;
    position: absolute;
    color: white;
    left: 10px;
    top: 10px;
    background-color: #ed6f00;
    padding: 0px 12px;
    border-radius: 0px 10px;
  }

  .img {
    width: 100%;
    border-radius: 10px 10px 0px 0px;
    cursor: pointer;
  }

  .cardName {
    display: flex;
    justify-content: space-between;
    align-items: end;
    padding: 10px;
    color: black;
  }

  .outOfStock {
    color: #ff0a4e;
    font-size: 12px;

    .inStockStatus {
      margin-left: 2px;
    }
  }

  .inStock {
    color: #00a700;
    font-size: 12px;
    display: flex;
    align-items: center;

    .inStockStatus {
      margin-left: 2px;
    }
  }

  .cardInfo {
    border-radius: 0px 0px 10px 10px;
    color: black;
    display: flex;
    justify-content: space-between;
    align-items: end;

    .priceWrap {
      display: flex;
      align-items: center;
      padding: 10px;
      flex-wrap: wrap;

      .newPrice {
        margin-right: 10px;
      }

      .discount {
        text-decoration: line-through;
        color: #8d8d8d;
        font-size: 14px;
      }
    }
    .button {
      border: 1px solid rgb(255, 194, 15);
      border-radius: 10px 0px 10px 0px;
      padding: 10px;
      background-color: rgb(255 194 15);
      cursor: pointer;
      transition: 0.5s;
      min-width: 100px;

      &:hover {
        transition: 0.5s;
        border: 1px solid #ffffff;
        color: white;
      }
    }
  }
}


