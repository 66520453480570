@import 'styles/colors.module';

.searchOpen {
  margin-top: 8px;
  padding: 5px 20px;
  background: linear-gradient($white, $white) padding-box, linear-gradient(45deg, $orange, $lilac) border-box;
  border: 2px solid transparent;
  border-radius: 50px;
}

.searchContainer {
  color: $gray-400;
  transition: 0.5s;
  display: flex;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Roboto, Ubuntu, sans-serif;
  align-items: center;
  position: relative;
  margin-right: 10px;

  .searchBtn {
    width: 20px;
    cursor: pointer;
  }

  .inpShow {
    transition: 0.5s;
    opacity: 1;
    width: 600px;
    height: auto;
    margin: 0px 10px;
    border: none;
    outline: none;
  }

  .inpHide {
    opacity: 0;
    width: 0px;
    height: 0px;
  }

  .xmarkHide {
    width: 20px;
    cursor: pointer;
    display: none;
  }

  .xmarkShow {
    width: 20px;
    cursor: pointer;
    display: block;
  }

  :hover {
    transition: 0.5s;
    color: #ffc20f;
  }

  .cardContainerLoader {
    text-align: center;
  }

  .cardContainer {
    position: absolute;
    top: 34px;
    width: 100%;
    left: 0px;
    padding: 3px 20px;
    background: linear-gradient(#fff, #fff) padding-box, linear-gradient(47deg, #f48803, #03e9f4) border-box;
    border: 2px solid transparent;
    border-radius: 4px;
    color: black;
    height: 85vh;
    overflow: auto;

    &:hover {
      color: black;
    }

    h4 {
      margin: 10px 0px;
    }

    .empty {
      color: #f76c79;
      letter-spacing: 2px;
    }
  }
}

@media only screen and (max-width: 980px) {
  .searchOpen {
    position: absolute;
    z-index: 9;
    width: 100%;
    justify-content: space-between;
  }

  .searchContainer {
    margin-right: 0px;
    .inpShow {
      width: auto;
    }
  }
}

@media only screen and (max-width: 1300px) {
  .searchContainer {
    .inpShow {
      width: 380px;
    }
  }
}
