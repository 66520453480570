.footer {
  border-top: 1px solid #ebebebe8;

  .wrapper {
    width: 70%;
    margin: auto;
    padding: 35px 0 20px 0;

    .title {
      color: white;
      font-size: 16px;
    }

    .paymentMethods {
      margin-top: 20px;
    }

    .content {
      font-size: 14px;
      letter-spacing: .5px;
      display: flex;
      justify-content: space-between;
      color: #6a6a6a!important;

      .storeInfo {
        max-width: 320px;
        position: relative;
        display: flex;

        .filler {
          background-color: rgb(39 39 39);
          border-radius: 50%;
          width: 50px;
          height: 50px;
          margin-right: 20px;

          .logo {
            width: 50px;
            height: 50px;
          }
        }

        .info {
          max-width: 250px;

          .support {
            width: 100%;
            display: flex;
            margin: 10px 0;
            align-items: center;
            flex-wrap: wrap;

            .email {
              color: white;
            }
          }
        }
      }

      .links {
        width: 220px;
        .item {
          margin-top: 5px;
        }

        .link {
          display: flex;
        }

        .title {
          margin-bottom: 20px;
        }
        a {
          display: inline-block;
          margin-top: 8px;

          &:hover {
            text-decoration: underline;
          }
        }

        a:nth-child(n+2) {
          margin-left: 8px;
        }

        .instagram {
          width: 22px;
        }

        .telegram {
          width: 22px;
        }

        svg {
          cursor: pointer;

          &.instagram:hover {
            color: #C13584!important;
          }

          &.telegram:hover {
            color: #3390ec!important;
          }
        }
      }
    }
  }

  .company {
    width: 100%;
    text-align: center;
    color: white;
    margin: 20px 0;
  }
}


@media only screen and (min-width: 1700px) {
  .wrapper {
    width: 1100px!important;
  }
}

@media only screen and (max-width: 980px) {
  .wrapper {
    width: 100% !important;
    padding: 20px 15px!important;
  }
}

@media only screen and (max-width: 630px) {
  .footer {
    .content {
      flex-wrap: wrap;
      justify-content: center!important;

      .storeInfo {
        max-width: unset!important;
        width: 100%;
        margin-bottom: 20px;
        display: flex;
        align-items: flex-end;
        font-size: 12px;

        .filler {
          .logo {
            margin-right: 25px;
          }
        }
      }


      .links {
        max-width: 220px;
        margin-top: 20px;
        &:nth-child(n+3) {
          //margin-left: 70px;
        }
      }
    }
  }
}
