.layout {
  display: flex;
  margin: auto;
  height: 100%;
  flex-direction: column;

  .empty {
    padding-top: 100px;
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .main {
    width: 100%;
    padding-top: 80px;

    .joistek1 {
      background-image: url(/joistek_1.png);
      transform: rotate(-24deg);
      width: 212px;
      //background-size: cover;
      flex: 0 0 360px;
      height: 151px;
      position: absolute;
      top: 350px;
      left: 60px;
      background-size: 75%;
      background-repeat: no-repeat;
      background-position: center;
      z-index: -10;
    }

    .joistek2 {
      background-image: url(/joistek_2.png);
      transform: rotate(24deg);
      width: 222px;
      //background-size: cover;
      flex: 0 0 360px;
      height: 164px;
      position: absolute;
      top: 350px;
      right: 60px;
      background-size: 75%;
      background-repeat: no-repeat;
      background-position: center;
      z-index: -10;
    }
  }
}

.titleContainer {
  margin-top: 50px;
  //background: linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(82,54,16,1) 31%, rgb(255, 194, 15) 60%, rgba(255,226,0,0) 74%);

  .title {
    color: white;
    position: relative;
    font-size: 35px;
    width: 70%;
    margin: auto;
  }
}

.allCardContainer {
  width: 70%;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-top: 50px;
}

@media only screen and (max-width: 980px) {
  .layout {
    .main {
      width: 100%;

      .joistek1 {
        left: 25px!important;
      }

      .joistek2 {
        right: 25px!important;
      }
    }
  }
  .allCardContainer {
    width: 100%;
  }

  .titleContainer {
    padding: 0px 12px;
    .title {
      width: auto;
    }
  }
}

.loader {
  width: 100%;
  text-align: center;
}

@media only screen and (min-width: 1700px) {
  .allCardContainer {
    width: 1100px;
  }

  .titleContainer {
    .title {
      width: 1100px;
    }
  }
}
