.modal {
  border-radius: 10px;
  background-color: #eeeeee;
  color: black;
  width: 200px;
  cursor: default;
  margin-top: 20px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

  .username {
    padding: 10px 15px;
    font-weight: bold;
    border-bottom: 1px solid white;
  }

  .link {
    cursor: pointer;
    padding: 10px 15px;
    transition: 0.5s;
    display: flex;
    align-items: center;

    .img {
      border-radius: 50%;
      object-fit: fill;
      margin-right: 5px;
    }

    &:hover {
      transition: 0.5s;
      .title {
        text-decoration: underline;
      }
    }
  }
}

.icon {
  font-size: 15px;
  margin-right: 5px;
}
