.container {
  height: 28px;

  .noteWrap {
    display: flex;
    justify-content: center;

    .note {
      background-color: #8d8d8d;
      padding: 3px 100px;
      color: #e3e3e3;
      border-radius: 15px;
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      position: relative;

      .closeNote {
        position: absolute;
        margin-right: 5px;
        top: 4px;
        right: 10px;
        width: 12px;
        cursor: pointer;
        color: black;
        transition: 0.5s;
        &:hover {
          color: #ff253a;
          transition: 0.5s;
        }
      }
    }
  }
}

@media only screen and (max-width: 980px) {
  .container {        color: #e3e3e3;

    .noteWrap {
      padding: 3px 22px!important;

      .note {
        padding: 3px 18px;
        text-align: center;

        .text {
          padding-right: 13px;
        }
      }
    }
  }
}

