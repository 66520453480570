.wrapper {
  position: fixed;
  z-index: 9999;
  height: 100%;
  top: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.85);

  .header {
    background-color: #e5e8eb;
    margin: 20px 20px 0 20px;
    padding: 10px 25px;
    display: flex;
    justify-content: flex-end;

    .close {
      z-index: 1;
      cursor: pointer;

      &:hover {
        color: #f76c79;
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  .wrapper {
    .header {
      background-color: white;
    }
  }
}
