.nav {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  backdrop-filter: blur(4px);
  display: flex;
  justify-content: flex-end;

  .mask {
    width: 100%;
  }

  .wrapper {
    height: 100%;
    flex-basis: 400px;
    background-color: rgb(39 39 39);
    overflow: auto;

    .link {
      padding: 15px;
      color: rgb(235 235 235 / 91%);
      border-bottom: 1px solid #8f8f8f;
      cursor: pointer;
      transition: 0.5s;

      &:hover {
        transition: 0.5s;
        .title {
          text-decoration: underline;
        }
      }

      .arrow {
        width: 22px;
        float: right;
      }

      .icon {
        width: 20px;
        margin-right: 10px;
      }
    }

    .subLink {
      color: rgb(235 235 235 / 91%);
      border-bottom: 1px solid #8f8f8f;
      cursor: pointer;
      transition: 0.5s;

      .wrap {
        padding: 15px;
        margin-left: 38px;
        display: flex;
        align-items: center;

        .img {
          border-radius: 50%;
          object-fit: fill;
          margin-right: 5px;
        }
      }

      &:hover {
        transition: 0.5s;
        .title {
          text-decoration: underline;
        }
      }
    }

    .mobHeader {
      height: 80px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 15px;
      border-bottom: 1px solid #8f8f8f;
      color: #8f8f8f;

      .close {
        width: 20px;
        cursor: pointer;

        &:hover {
          color: rgb(235 235 235 / 91%);
        }
      }
    }
  }
}

@media only screen and (max-width: 400px) {
  .nav {
    .mask {
      width: 0%;
    }

    .wrapper {
      flex-basis: 100%;
    }
  }
}