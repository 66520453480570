.productSticker {
  position: absolute;
  left: -8px;
  top: 90px;
  line-height: 1;

  .container {
    position: relative;
    z-index: 1;
    display: table;
    height: 24px;
    line-height: 24px;
    margin-top: 2px;
    padding: 0 9px;
    border-radius: 2px 2px 2px 0;
    color: #ff253a;
    background: currentColor;
    font-size: 14px;

    .value {
      color: white;
    }

    &:before {
      content: '';
      position: absolute;
      top: 100%;
      left: 0;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 8px 8px 0;
      border-color: transparent currentColor transparent transparent;
    }
  }
}
