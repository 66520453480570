.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  flex-direction: column;
  padding: 10px;
  text-align: center;

  .title {
    color: white;
    font-size: 22px;
    margin-bottom: 40px;
  }

  .btn {
    margin: 20px;
  }
}