@import 'styles/colors.module';

.loading {
  position: relative;
  padding: 10px 65px 10px 20px!important;
  overflow: hidden;
}

.disabled {
  color: #939393!important;
  border: 1px solid #939393!important;
  cursor: auto;

  &:hover {
    background-color: transparent!important;
  }
}

.btn {
  padding: 10px 20px;
  color: white;
  font-size: 16px;
  letter-spacing: 4px;
  cursor: pointer;
  background: none;
  border: 1px solid white;
  border-radius: 5px;
  transition: 0.7s;



  &:hover {
    background-color: white;
    transition: 0.7s;
    //background: #03e9f4;
    color: black;
    border: 1px solid black;
    //box-shadow: 0 0 5px $lilac,
    //          0 0 25px $lilac,
    //          0 0 50px $lilac,
    //          0 0 100px $lilac;
  }
}
